

$(() => {

  if (!$('.session-planning').length) {
    return;
  }

  // refresh uts
  const refreshUTs = () => {
    $('th.day').each(function() {
      const th  = $(this);
      const day = th.data('day');
      const ut = Number($('#ut-' + day).text());
      let ut_conso = 0;
      $('.session-categories[data-day=' + day + '] .category').each(function() {
        const cat_ut = $(this).data('ut');
        ut_conso += (cat_ut || 0);
      });
      ut_conso = Math.round(ut_conso * 100) / 100;
      $('#ut_conso-' + day).text(ut_conso);
      $('#ut_dispo-' + day).text(ut - ut_conso);
    });
  };
  refreshUTs();

  // update available categories
  const updateCategories = function() {

    $('.session-categories').each(function() {
      const parent = $(this);
      const categories = parent.find('.category').toArray().map(el => $(el).data('id'));
      const day = parent.data('day');
      const daycategories = $(`td.daycategories.day-${day} .category`).toArray().map(el => $(el).data('id'));
      let has_items = false;
      parent.find('.dropdown-item').each(function() {
        const id = $(this).data('id');
        const category_visible = categories.indexOf(id) < 0 && (isNaN(id) || daycategories.indexOf(id) >= 0);
        has_items = has_items || category_visible;
        // show/hide category in dropdown menu
        $(this).toggle(category_visible);
      });

      // show/hide + button
      parent.find('.add-category').toggle(has_items);

      // update hidden field
      parent.find('input[type=hidden]').val(categories.join(','));
    });

  };
  updateCategories();

  
  // add category
  const addCategory = function() {
    const id        = $(this).data('id');
    const name      = String($(this).data('name'));
    const parent    = $(this).closest('.session-categories');
    const bg        = isNaN(id) ? 'bg-success' : 'bg-secondary';
    const badge     = $('<span/>', { class: `badge ${bg} category me-1` });
    const ut        = $(this).data('ut');
    badge.data('id', id);
    badge.data('ut', ut);
    badge.text(name);

    // verify availability
    const day = parent.data('day');
    const ut_dispo = Number($('#ut_dispo-' + day).text());
    if (ut > ut_dispo) {
      window.alert('Il n\'y a pas assez d\'UT disponible pour ajouter cette catégorie en test');
      return false;
    }

    // insert badge at correct position
    let inserted = false;
    parent.find('.category').each(function() {
      if (inserted) {
        return;
      }
      const el = $(this);
      if (name.localeCompare(el.text()) < 0) {
        badge.insertBefore(el);
        inserted = true;
      }
    });
    if (!inserted) {
      parent.find('.categories').append(badge);
    }

    updateCategories();
    refreshUTs();
    return false;
  };
  $(document).on('click', '.session-categories .dropdown-item', addCategory);

  // remove category
  const removeCategory = function() {
    $(this).remove();
    updateCategories();
    refreshUTs();
  };
  $(document).on('click', '.category', removeCategory);

}); 